@use '../../partials/variables' as *;
@use '../../partials/mixins' as *;

.nav {
    background-color: $Teal;
    width: 100%;
    height: 5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    @include desktopBreakpoint {
        height: 10vh;
    }
    &__container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: $Pink;
        width: 5rem;
        height: 5rem;
        @include desktopBreakpoint {
            height: 10vh;
            width: 10vh;
        }
    }
    &__logo {
        width: 70%
    }
    &__header {
        margin: 0;
        font-size: 1.4rem;
        padding: 0 1rem;
        color: $Yellow;
        @include tabletBreakpoint {
            font-size: 1.7rem;
        }
        &--alternate {
            color: $LightGreen;
        }
    }
}