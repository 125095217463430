@use '../../partials/variables' as *;
@use '../../partials/mixins' as *;

.about {
    width: 100%;
    &__headerContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $Teal;
    }
    &__headerTabContainer {
        width: 60rem;
        height: 4rem;
        display: flex;
    }
    &__headerBackMain {
        width: 10rem;
        background-color: $Teal;
        @include tabletBreakpoint {
            width: 18rem;
        }
    }
    &__headerBackLeft {
        width: 1rem;
        background-color: $Egg;
        border-top-left-radius: 10px;
    }
    &__headerFrontLeft {
        width: 100%;
        height: 100%;
        border-bottom-right-radius: 10px;
        background-color: $Egg;
        @include tabletBreakpoint {
            background-color: $Teal;
        }
    }
    &__headerBackRight {
        width: 1rem;
        flex-grow: 1;
        background-color: $Egg;
    }
    &__headerFrontRight {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 10px;
        background-color: $Teal;
    }
    &__header {
        width: 100%;
        margin: 0;
        padding: 1rem 1rem 1rem 0;
        color: $Teal;
        background-color: $Egg;
        font-size: 1.2rem;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        @include tabletBreakpoint {
            font-size: 2rem;
            padding: 1rem 3rem;
            border-top-left-radius: 10px;
        }
    }
    &__headerIcon {
        height: 2rem;
        padding-left: 1rem;
    }
    &__bodyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        background-color: $Egg;
        padding: 2rem 0;
        @include tabletBreakpoint {
            padding: 3rem 2rem;
        }
        @include desktopBreakpoint {
            align-items: center;
        }
    }
    &__body {
        color: $Teal;
        font-size: 1.05rem;
        padding: 1rem;
        margin: 0;
        line-height: 1.7;
        @include tabletBreakpoint {
            font-size: 1.75rem;
            line-height: 1.7;
            font-weight: 500;
        }
        @include desktopBreakpoint {
            width: 40rem;
        }
    }
}