@use '../../partials/variables' as *;
@use '../../partials/mixins' as *;

.projects {
    width: 100%;
    &__headerContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $Egg;
        width: 100%;
    }
    &__headerTabContainer {
        display: flex;
        width: 100%;
        height: 4rem;
        justify-content: flex-end;
        @include tabletBreakpoint {
            width: 60rem;
        }
    }
    &__headerBackLeft {
        background-color: $Teal;
        height: 100%;
        width: 50%;
        flex-grow: 1;
    }
    &__headerFrontLeft {
        height: 100%;
        width: 100%;
        background-color: $Egg;
        border-bottom-right-radius: 10px;
    }
    &__headerBackRight {
        width: 1rem;
        background-color: $Teal;
        border-top-right-radius: 10px;
    }
    &__headerFrontRight {
        width: 100%;
        height: 100%;
        border-bottom-left-radius: 10px;
        background-color: $Teal;
        @include tabletBreakpoint {
            background-color: $Egg;
        }
    }
    &__headerBackMain {
        height: 100%;
        width: 13rem;
        background-color: $Egg;
        @include tabletBreakpoint {
            width: 21.5rem;
        }
    }
    &__header {
        margin: 0;
        width: 100%;
        padding: 1rem;
        color: $Egg;
        background-color: $Teal;
        border-top-left-radius: 10px;
        font-size: 1.2rem;
        text-align: end;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @include tabletBreakpoint {
            font-size: 2rem;
            padding: 1rem 3rem;
            border-top-right-radius: 10px;
        }
    }
    &__headerIcon {
        height: 2.5rem;
        padding-right: 1rem;
        @include desktopBreakpoint {

        }
    }
    &__bodyContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $Teal;
        padding: 1rem;
        @include tabletBreakpoint {
            padding: 5rem 2rem;
        }
    }
    &__itemContainer {
        margin: 1rem;
        width: 100%;
        padding: 0 1rem 1rem 1rem;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: rgba(0, 0, 0, 0.404);
        @include tabletBreakpoint {
            padding: 0 2rem 2rem 2rem;
        }
        @include desktopBreakpoint {
            width: 40rem;
        }
    }
    &__itemHeader {
        font-size: 1.2rem;
        color: $Egg;
        padding: 0 1rem;
        width: 100%;
        @include tabletBreakpoint {
            font-size: 1.75rem;
        }
    }
    &__itemBody {
        text-align: start;
        color: $Egg;
        line-height: 1.5;
        font-size: 1.05rem;
        padding: 1rem;
        margin: 0;
        @include tabletBreakpoint {
            font-size: 1.7rem;
        }
    }
    &__itemLink {
        text-decoration: none;
        color: $Egg;
        width: 100%;
        display: flex;
        padding-left: 1rem;
        align-items: center;
        color: $Yellow;
        @include tabletBreakpoint {
            font-size: 1.7rem;
        }
    }
    &__itemLinkIcon {
        height: 1.2rem;
        padding: 0 0.5rem;
    }
    &__imageContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    &__itemImage {
        width: 44%;
        flex-grow: 1;
        margin: 0.5rem;
        object-fit: scale-down;
        border-radius: 10px;
        @include tabletBreakpoint {
            width: 20%;
        }
    }
}