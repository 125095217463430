// Primary color palette
$Egg: #e8f2eb;
$Gray: #bdc7b4;
$Teal: #349b83;


// Secondary colors
$Yellow: #f1ef44;
$LightGreen: #83cc28;
$Green: #78bb22;
$Pink: #ab6f73;

// Media Query Widths
$desktopWidth: 1280px;
$tabletWidth: 768px;