@use '../../partials/variables' as *;
@use '../../partials/mixins' as *;

$duration: 30s;

@-webkit-keyframes ticker {
    0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
    }

    100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
    }

    100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    }
}

.ticker {
    width: 100%;
    overflow: hidden;
    &__wrap {
        bottom: 0;
        width: 100%;
        overflow: hidden;
        height: 2rem;
        background-color: $Pink; 
        padding-left: 100%;
        box-sizing: content-box;
        @include tabletBreakpoint {
            height: 3rem;
        }
        @include desktopBreakpoint {
            height: 100%;
        }
    }
    &__itemContainer {
        display: inline-block;
        height: 2rem;
        line-height: 2rem;  
        white-space: nowrap;
        padding-right: 100%;
        box-sizing: content-box;
        -webkit-animation-iteration-count: infinite; 
        animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-timing-function: linear;
        -webkit-animation-name: ticker;
        animation-name: ticker;
        -webkit-animation-duration: $duration;
        animation-duration: $duration;
        overflow: hidden;
        @include tabletBreakpoint {
            height: 3rem;
            line-height: 3rem;
        }
        @include desktopBreakpoint {
            height: 5vh;
        }
    }
    &__item {
        display: inline-block;
        padding: 0 2rem;
        font-size: 1rem;
        overflow: hidden;
        margin: 0;
        color: White;
        @include tabletBreakpoint {
            font-size: 1.4rem;
        }
        @include desktopBreakpoint {
            height: 100%;
            font-size: 1.6rem;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            line-height: normal;
        }   
        }
    &__itemText {
        margin: 0;
        @include desktopBreakpoint {
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
}
