@use '../../partials/variables' as *;
@use '../../partials/mixins' as *;

.techStack {
    width: 100%;
    background-color: $Egg;
    display: flex;
    flex-direction: column;
    align-items: center;
    &__headerContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        background-color: $Teal;
    }
    &__headerTabContainer {
        width: 100%;
        height: 4rem;
        display: flex;
        @include tabletBreakpoint {
            width: 60rem;
        }
    }
    &__headerBackLeft {
        width: 1rem;
        height: 100%;
        background-color: $Egg;
        border-top-left-radius: 10px;
    }
    &__headerFrontLeft {
        width: 100%;
        height: 100%;
        background-color: $Egg;
        border-bottom-right-radius: 10px;
        @include tabletBreakpoint {
            background-color: $Teal;
        }
    }
    &__headerBackRight {
        width: 1rem;
        flex-grow: 1;
        background-color: $Egg;
    }
    &__headerFrontRight {
        width: 100%;
        height: 100%;
        background-color: $Teal;
        border-bottom-left-radius: 10px;
    }
    &__headerMain {
        background-color: $Teal;
        height: 100%;
        width: 17rem;
        @include tabletBreakpoint {
            width: 31rem;
        }
    }
    &__header {
        margin: 0;
        padding: 1rem 1rem 1rem 0;
        color: $Teal;
        width: 100%;
        height: 100%;
        flex-grow: 1;
        background-color: $Egg;
        font-size: 1.2rem;
        border-top-right-radius: 10px;
        display: flex;
        align-items: center;
        @include tabletBreakpoint {
            font-size: 2rem;
            padding: 1rem 3rem;
            border-top-left-radius: 10px;
        }
    }
    &__headerIcon {
        height: 2rem;
        padding-left: 1rem;
    }
    &__list {
        display: flex;
        margin: 0;
        padding: 2rem 1rem;
        list-style: none;
        width: 17rem;
        flex-wrap: wrap;
        justify-content: center;
        @include tabletBreakpoint {
            padding: 5rem 1rem;
            width: 22rem;
            height: 40rem;
            align-items: center;
        }
    }
    &__item {
        height: 5rem;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 1rem 0;
        padding: 0;
        color: Black;
        font-size: 1.1rem;
        font-weight: 700;
    }
    &__iconLarge {
        height: 2rem;
        @include tabletBreakpoint {
            height: 3rem;
        }
    }
    &__icon {
        height: 4rem;
        @include tabletBreakpoint {
            height: 6rem;
        }
    }
}